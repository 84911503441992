import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImgCropperComponent } from 'src/app/shared/components/img-cropper/img-cropper.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import countryList from '../../../../assets/data-json/countrys.json';
import sectorList from '../../../../assets/data-json/sector.json';
import gender from '../../../../assets/data-json/gender.json';
import { environment } from 'src/environments/environment';
import { SubscriptionsService } from '../../services/subscriptions.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss']
})
export class SubscriptionFormComponent implements OnInit {
  @Input() eventId;
  private sourceUrl = environment.apis.document_path
  public countrys: any[] = countryList;
  public organizationActivities: any[] = sectorList;
  public genderItems: any[] = gender;
  subscriptionForm: FormGroup;

  submited: boolean = false;
  loaderSubscription: boolean = false;
  curentUser: any;
  terms = false;
  selectedPayment: string = 'tabc'; // Valeur par défaut
  showCotisation: boolean = true;

  isNonTunisian: boolean = false;
  isTunisian: boolean = false;

  // Méthode pour basculer l'affichage du select
  toggleSelect(show: boolean): void {
    this.showCotisation = show;
    if (!show) {
      // Réinitialiser la valeur de cotisation si le paiement n'est pas en ligne
      this.subscriptionForm.get('cotisation').setValue('');
    }
  }
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private subscriptionsService: SubscriptionsService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit(): void {

    this.initForm();
    if(sessionStorage.getItem('token')){
      this.curentUser = JSON.parse(localStorage.getItem('me'))
      this.userService.getUserByID(this.curentUser.ID).subscribe(userInfo => {
        this.patchFormValues(userInfo.Data[0])
      })
    }

    this.subscriptionForm.get('country').valueChanges.subscribe(selectedCountryCode => {
      this.isNonTunisian = selectedCountryCode === 'TN';
      this.isTunisian = selectedCountryCode !== 'TN';
    });

  }

  initForm() {
    this.subscriptionForm = this.formBuilder.group({
      id: new FormControl(''),
      last_name: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      first_name: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$')], this.subscriptionsService.checkExistenceSubscription(this.eventId)),
      picture: new FormControl(''),
      gender: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      country: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      phone: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      society: new FormControl('', [Validators.required, this.noWhitespaceValidator, Validators.min(2)]),
      participantFunction: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      sector: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      product_services: new FormControl(''),
      website: new FormControl(''),
      eventId: new FormControl(this.eventId),
      language: new FormControl(localStorage.getItem('lang')),
      cotisation: new FormControl(''),
    })
  }


  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { required: true };
  }


  patchFormValues(data) {
    this.subscriptionForm.patchValue({
      id: data.userId,
      last_name: data.last_name,
      first_name: data.first_name,
      email: data.user_email,
      picture: data.profilePicture,
      gender: data.gender,
      country: data.country,
      phone: data.phone,
      society: data.society,
      participantFunction: data.participantFunction,
      sector: data.sector,
      product_services: data.product_services,
      website: data.website,
    })

  }

  pictuteChange(event) {
    const fileList: FileList = event.target.files;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.openCropperModal(e.target.result, fileList[0].name);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  openCropperModal(srcfile, fileName: string) {
    const modalRef = this.modalService.open(ImgCropperComponent, {
      size: 'xl',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.srcFile = srcfile;
    modalRef.componentInstance.fileName = fileName;
    modalRef.componentInstance.ratio = 1;
    modalRef.componentInstance.isPublic = true;
    modalRef.result.then((result) => {
      if (result && result != "Cross click") {
        this.subscriptionForm.get('picture').setValue(this.sourceUrl + result.MediaMetadata.upload_file);
      }
    });
  }

  focusedField(event) {
    if (event.type == 'focus') {
      event.target.closest('.label-floating').classList.add('is-focused');
    } else {
      event.target.closest('.label-floating').classList.remove('is-focused');
    }
  }

  clearCover() {
    this.subscriptionForm.get('picture').setValue('');
  }

  saveEvent() {
    this.submited = true;
    if (this.subscriptionForm.valid) {
      this.loaderSubscription = true;
      this.subscriptionForm.get('email').setValue(this.subscriptionForm.get('email').value.toLowerCase());
      this.subscriptionsService.addSubscription(this.subscriptionForm.value).subscribe(res => {
        this.toastService.success();
        const cotisation = this.subscriptionForm.get('cotisation').value;

        if (cotisation === '1000000') {
          window.open('https://knct.me/tHm@veXpc', '_blank');
        } else if (cotisation === '500000') {
          window.open('https://knct.me/OCXty7Sys', '_blank');
        } else if (cotisation === '250000') {
          window.open('https://knct.me/J8vwFFYhm', '_blank');
        }

        this.activeModal.close()
        this.submited = false;
        this.loaderSubscription = false;
      }, error => {
        if (error?.error?.message == 'You are already subscribed !!') {
          this.toastService.info('Vous êtes déjà abonné');
        } else {
          this.toastService.info('Service temporairement indisponible');
        }
        this.submited = false;
        this.loaderSubscription = false;
      })
    }
  }

  cancel() {
    this.subscriptionForm.reset();
  }

}
